var render = function () {
  var _vm$place;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "bg-violet bottom-0 left-0 right-0 mt-28"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "w-full flex text-sm text-center bg-violet-light py-1 text-white items-center justify-center"
  }, [_c('p', [_vm._v("\n      جميع الحقوق محفوظة لـ " + _vm._s((_vm$place = _vm.place) === null || _vm$place === void 0 ? void 0 : _vm$place.name) + " ©\n      " + _vm._s(_vm.currentYear()) + "\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "mx-4"
  }, [_vm._v("|")]), _vm._v(" "), _vm._m(1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-2 mx-auto text-white-700 max-w-7xl sm:px-6 lg:px-8"
  }, [_c('div', {
    staticClass: "flex flex-wrap pb-7 pt-6 lg:flex-nowrap"
  }), _vm._v(" "), _c('div', {
    staticClass: "flex flex-wrap justify-between pb-12 last lg:flex-nowrap"
  }, [_c('div', {
    staticClass: "flex flex-wrap items-center justify-center flex-1/1 lg:flex-auto lg:justify-start md:flex-nowrap"
  }, [_c('div', {
    staticClass: "flex items-center"
  })]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center justify-end mt-5 flex-1/1 lg:flex-auto lg:mt-0"
  }, [_c('img', {
    staticClass: "me-3",
    attrs: {
      "src": require("images/Home/payments.svg"),
      "alt": ""
    }
  })])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("\n      صنع باحترافية على\n      "), _c('a', {
    staticClass: "underline",
    attrs: {
      "href": "https://eventful.sa"
    }
  }, [_vm._v("Eventful Business")])]);
}]

export { render, staticRenderFns }