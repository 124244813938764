//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import OneStepLoginActivateCodeModal from '~/components/Modals/OneStepLoginActivateCodeModal'

export default {
  name: 'NavLinks',
  components: {
    OneStepLoginActivateCodeModal,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navbar: [
        {
          title: 'nav.home',
          url: '/',
          icon: 'fa-home',
        },
        {
          title: 'nav.services',
          url: '/#services',
          icon: 'fa-info-circle',
        },
        {
          title: 'nav.about',
          url: '/about',
          icon: 'fa-info-circle',
        },
        {
          title: 'nav.offers',
          url: '/options',
          icon: 'fa-filter',
        },
        {
          title: 'nav.contact',
          url: '/contact',
          icon: 'fa-headset',
        },
      ],
      selectedBranch: null
    }
  },
  async created() {
    await this.getBranches()
  },
  computed: {
    place() {
      return this.$store.state.home.place ?? {}
    },
    branches() {
      return this.$store.state.searchBox.branches ?? []
    },
  },
  methods: {
    ...mapMutations({
      toggleOneStepLoginModal: 'login/toggleOneStepLoginModal',
    }),
    logout() {
      this.$store.dispatch('login/doLogout')
    },
    makeCall() {
      window.open(`tel:${this.place.phone}`)
    },
    async getBranches() {
      const response = await this.$store.dispatch('searchBox/getBranches', this.place.id)
      response.places.forEach(b => {
          if(b.id === this.place.id) this.selectedBranch = b;
      });
    },
  },
  watch: {
    selectedBranch: function(newVal) {
      console.log('got the branch!!!!');
      if(newVal && newVal.id != this.place.id)
        window.location.href = newVal.subdomain_url;
    }
  }
}
