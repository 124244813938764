//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
  },
  mounted() {
    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };
    const onClose = (e) => {
      if (!e.target.className.includes('dontCloseDropdown')) {
        this.open = false;
      }
    };

    document.addEventListener("keydown", onEscape);
    document.addEventListener("click", onClose);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
      document.removeEventListener("click", onClose);
    });
  },
};
