var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.place ? _c('section', {
    attrs: {
      "className": "flex flex-wrap relative z-40"
    }
  }, [_c('div', {
    staticClass: "fixed w-full left-0 right-0 top-0 z-40 max-w-full h-auto bg-white shadow-xl"
  }, [_c('div', {
    staticClass: "flex flex-col md:h-full relative  "
  }, [_c('div', {
    staticClass: "relative z-10 w-full flex flex-row justify-between max-h-[70px]",
    attrs: {
      "id": "headerBar"
    }
  }, [_c('div', {
    staticClass: "flex flex-col md:flex-row md:items-center max-h-full"
  }, [_c('NuxtLink', {
    staticClass: "flex ps-5 items-center h-full relative max-h-full",
    attrs: {
      "to": "/"
    }
  }, [_vm.place.logo ? _c('img', {
    staticClass: "block w-auto h-full max-h-full md:max-h-24 py-2 object-contain",
    attrs: {
      "src": _vm.place.logo,
      "alt": "Place logo"
    }
  }) : _vm._e(), _vm._v(" "), _c('h1', {
    staticClass: "text-2xl ms-8 text-primary sm:block",
    class: {
      hidden: _vm.place.logo
    }
  }, [_vm._v("\n              " + _vm._s(_vm.place.arabic_name) + "\n            ")])])], 1), _vm._v(" "), _c('NavLinks', {
    staticClass: "hidden lg:flex"
  }), _vm._v(" "), _c('ul', {
    staticClass: "flex ms-4 items-center justify-center flex lg:hidden mx-10 gap-8"
  }, [_c('li', {
    staticClass: "flex whitespace-nowrap border border-green-light text-green-light items-center justify-center md:text-xl hover:bg-green-light hover:text-white"
  }, [_c('NuxtLink', {
    staticClass: "px-4 py-2",
    attrs: {
      "to": "/options",
      "exact-active-class": "text-gray-600 hover:text-white"
    }
  }, [_c('span', [_vm._v("احجز الآن")])])], 1), _vm._v(" "), _c('li', {
    on: {
      "click": _vm.toggleNav
    }
  }, [_vm._m(0)])]), _vm._v(" "), _c('ul', {
    staticClass: "flex ms-4 items-center justify-center hidden lg:flex"
  }, [_c('li', {
    staticClass: "flex px-12 bg-green-light text-white p-4 h-full min-h-full items-center justify-center text-xl"
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/options"
    }
  }, [_c('span', [_vm._v("احجز الآن")])])], 1)])], 1), _vm._v(" "), _c('NavLinks', {
    staticClass: "z-40",
    class: {
      hidden: !_vm.showMenu,
      flex: _vm.showMenu
    },
    attrs: {
      "is-mobile": true
    }
  })], 1)]), _vm._v(" "), _c('div', {
    attrs: {
      "id": "headerReplacer"
    }
  })]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "text-primary text-2xl"
  }, [_c('i', {
    staticClass: "far fa-bars"
  })]);
}]

export { render, staticRenderFns }