import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=13342f10&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=13342f10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13342f10",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VDropdown: require('/var/app/current/components/v-dropdown.vue').default})
