//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import Vue from 'vue'
// import { ChevronDownIcon } from "@heroicons/vue/solid";
import VDropdown from '~/components/v-dropdown.vue'
import Header from '~/components/Subdomain/Header'

const navLinks = [
  {
    translate: 'nav.home',
    path: '/',
  },
  {
    translate: 'nav.search',
    path: '/search',
  },
]

const externalNavLinks = [
  {
    translate: 'nav.blog',
    path: 'https://blog.qosoor.sa',
    target: '_blank',
  },
  {
    translate: 'nav.about',
    path: 'aboutUs',
    target: '_self',
  },
  // {
  //   translate: 'nav.contact',
  //   path: '/contact',
  // },
]
// import { ChevronDownIcon } from "@heroicons/vue/solid";
export default {
  name: 'Navbar',
  component: { VDropdown, Header },
  props: {
  },
  // component:{ChevronDownIcon},
  data() {
    return {
      isOpenUserDetails: false,
      isOpenMenu: false,
      navLinks,
      externalNavLinks,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLoggedIn
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
    isOpenedProfileMenu() {
      return this.$store.state.navbar.isOpenedProfileMenu
    },
    currentUser(){
          return this.$store.state.login.user
        },
    myBookingsLink(){
      if(this.isPlaceApp)
      {
        return this.currentPlaceAppBaseUrl+"/myBookings"
      }else {
        return this.localePath('/dashboard/bookings')
      }

    },

    currentPlace() {
      return this.$store.state.home.place ?? {}
    },
    isPlannerAiPage() {
      return this.$route.fullPath == '/plannerAi'
    }
  },
  mounted() {
    window.addEventListener(
      'click',
      function (event) {
        const elment = $('#mobile-menu')
        const button = $('#btn-menu')
        const buttonSvg = $('#btn-menu>svg')
        const buttonSvgPath = $('#btn-menu>svg>path')
        if (elment.length) {
          if (
            event.target === elment[0] ||
            event.target === button[0] ||
            event.target === buttonSvgPath[0] ||
            event.target === buttonSvg[0]
          ) {
            return
          }
          this.toggleMenu()
        }
      }.bind(this)
    )
  },
  methods: {
    ...mapMutations({
      // toggleMenu: 'navbar/toggleMenu',
      toggleOneStepLoginModal: 'login/toggleOneStepLoginModal',
      toggleProfileMenu: 'navbar/toggleProfileMenu',
    }),
    toggleUserDetails() {
      this.isOpenUserDetails = !this.isOpenUserDetails
      // Ensures the event isn't registered too quickly and causes a double fire
      this.isOpenUserDetails &&
        setTimeout(() => {
          const app = document.getElementById('app')
          app.addEventListener('click', this.blurUserDetails, false)
          //   app.addEventListener(
          //     'mouseover',
          //     function (e) {
          //       const target = e.target.attributes?.role?.value || null
          //       const allowedTargets = ['menuitem', 'menu', 'user-menu-button']
          //       if (!target || !allowedTargets.indexOf(target)) {
          //         this.blurUserDetails()
          //       }
          //     }.bind(this),
          //     false
          //   )
        }, 250)

      // !this.isOpenUserDetails &&
      //   document
      //     .getElementsByTagName('body')[0]
      //     .removeEventListener('click', this.hide)
    },
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu
    },
    blurUserDetails() {
      this.isOpenUserDetails = false
      const app = document.getElementById('app')
      app.removeEventListener('click', this.blurUserDetails, false)
      // app.removeEventListener('mouseover', this.blurUserDetails, false)
    },
    doLogout() {
      this.$store.dispatch('login/doLogout')
    },
  },
}
