//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavLinks from '~/components/Subdomain/NavLinks'
import { log } from '~/plugins/console'

export default {
  name: 'Header',
  components: { NavLinks },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    place() {
      return this.$store.state.home.place ?? null
    },
  },
  watch: {
    $route(to, from) {
      this.showMenu = false
      this.applyHeaderSize()
    },
  },
  mounted() {
    this.applyHeaderSize()
    if (process.browser) {
      window.addEventListener('resize', this.applyHeaderSize)
    }
    // this.$router.history.listen((newLocation) => {
    //   // log('newLocation: ', newLocation)
    //   this.applyHeaderSize()
    // })
  },
  methods: {
    toggleNav() {
      this.showMenu = !this.showMenu
    },
    applyHeaderSize() {
      if (document.getElementById('headerReplacer')) {
        document.getElementById('headerReplacer').style.height =
          document.getElementById('headerBar').offsetHeight + 'px'
      }
    },
  },
}
