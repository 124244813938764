var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isPlaceApp && !_vm.isPlannerAiPage ? _c('nav', {
    staticClass: "\n        top-0\n        left-0\n        right-0\n        z-40\n        backdrop-filter backdrop-blur-sm\n      ",
    class: [_vm.isPlaceApp ? 'absolute bg-gray-900 bg-opacity-50' : 'fixed bg-white bg-opacity-90']
  }, [_c('div', {
    staticClass: "px-2 mx-auto max-w-7xl sm:px-6 lg:px-8"
  }, [_c('div', {
    staticClass: "relative flex items-center justify-between h-16"
  }, [_c('div', {
    staticClass: "absolute inset-y-0 flex items-center end-0 lg:hidden"
  }, [_c('button', {
    staticClass: "\n                inline-flex\n                items-center\n                justify-center\n                p-2\n                text-gray-400\n                rounded-md\n                ms-2\n                hover:bg-gray-100\n                focus:outline-none\n                focus:ring-2\n                focus:ring-inset\n                focus:ring-white\n              ",
    attrs: {
      "id": "btn-menu",
      "type": "button",
      "aria-controls": "mobile-menu",
      "aria-expanded": "false"
    },
    on: {
      "click": _vm.toggleMenu
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Open main menu")]), _vm._v(" "), !_vm.isOpenMenu ? _c('svg', {
    staticClass: "block w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M4 6h16M4 12h16M4 18h16"
    }
  })]) : _c('svg', {
    staticClass: "block w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M6 18L18 6M6 6l12 12"
    }
  })])])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center flex-1 "
  }, [!_vm.isPlaceApp ? _c('div', {
    staticClass: "flex items-end flex-shrink-0 me-4"
  }, [_c('NuxtLink', {
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('img', {
    staticClass: "block w-auto h-8",
    attrs: {
      "src": require("images/NewLogo.svg"),
      "alt": "eventful",
      "prefetch": ""
    }
  })])], 1) : _vm._e(), _vm._v(" "), _vm.currentUser ? _c('div', {
    staticClass: "flex items-center mx-2 cursor-pointer lg:gap-x-4",
    on: {
      "click": _vm.toggleProfileMenu
    }
  }, [_vm.isOpenedProfileMenu ? _c('div', {
    staticClass: "\n                  w-11/12\n                  sm:w-7/12\n                  md:w-5/12\n                  lg:w-3/12\n                  absolute\n                  top-16\n                  right-0\n                  overflow-hidden\n                  fixed\n                  z-50\n                  outline-none\n                  focus:outline-none\n                  bg-white\n                  text-secondary\n                  rounded-b-xl\n                  border-2\n                  end-4\n                "
  }, [_c('div', {
    staticClass: "relative w-full w-auto my-2"
  }, [_c('div', {
    staticClass: "flex flex-col divide-y text-sm "
  }, [_c('NuxtLink', {
    staticClass: "p-4 flex items-center justify-start",
    attrs: {
      "to": _vm.myBookingsLink
    }
  }, [_c('img', {
    staticClass: "text-secondary w-4",
    attrs: {
      "src": require("~/assets/images/dashboard/calander.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('h6', {
    staticClass: "ms-4 text-sm"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t('dashboard.bookings')) + "\n                      ")])]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "p-4 flex items-center justify-start",
    attrs: {
      "to": _vm.localePath('/businessEvents/MyEventRequests')
    }
  }, [_c('img', {
    staticClass: "text-secondary w-4",
    attrs: {
      "src": require("~/assets/images/dashboard/file.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('h6', {
    staticClass: "ms-4 text-sm"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t('dashboard.myBusinessEvents')) + "\n                      ")])]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "p-4 flex items-center justify-start",
    attrs: {
      "to": _vm.localePath('/planners')
    }
  }, [_c('img', {
    staticClass: "text-secondary w-4",
    attrs: {
      "src": require("~/assets/images/dashboard/file.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('h6', {
    staticClass: "ms-4 text-sm"
  }, [_vm._v("\n                        تنسيقاتي\n                      ")])]), _vm._v(" "), _c('div', {
    staticClass: "p-4 flex items-center justify-start",
    attrs: {
      "to": _vm.localePath('/')
    },
    on: {
      "click": _vm.doLogout
    }
  }, [_c('img', {
    staticClass: "text-secondary w-4",
    attrs: {
      "src": require("~/assets/images/logout.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('h6', {
    staticClass: "ms-4 text-sm"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t('nav.logout')) + "\n                      ")])])], 1)])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "hidden lg:block"
  }, [_c('img', {
    attrs: {
      "src": require("~/assets/images/dashboard/down_arrow.svg"),
      "alt": "notifications"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "hidden text-center lg:block",
    class: [_vm.isPlaceApp ? 'text-white' : '']
  }, [_c('h1', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.currentUser.full_name))])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center w-8"
  }, [_c('button', [_c('img', {
    attrs: {
      "src": require("~/assets/images/dashboard/profile_user.svg"),
      "alt": "notifications"
    }
  })])])]) : _vm._e(), _vm._v(" "), !_vm.isLogin ? _c('button', {
    staticClass: "\n                flex-no-wrap\n                text-sm\n                font-medium\n                text-white\n                flex-nowrap\n                focus:outline-none\n                text-sout\n              ",
    on: {
      "click": _vm.toggleOneStepLoginModal
    }
  }, [_c('span', {
    staticClass: "\n                  flex\n                  items-center\n                  px-5\n                  py-1\n                  transition-all\n                  rounded-full\n                  align-center\n                  bg-primary\n                  focus:bg-primary-dark\n                  hover:bg-primary-dark\n                "
  }, [_vm._v("\n                " + _vm._s(_vm.$t('nav.login')) + "\n                "), _c('client-only', [_c('i', {
    staticClass: "ps-2"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "user-lock"
    }
  })], 1)])], 1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "hidden lg:flex md:ms-auto items-center"
  }, [_c('div', {
    staticClass: "flex md:space-x-2 lg:space-x-8 items-center"
  }, [_vm._l(_vm.navLinks, function (navLink, index) {
    return !_vm.isPlaceApp ? _c('NuxtLink', {
      key: 'navTop' + index,
      staticClass: "\n                    flex-no-wrap\n                    px-3\n                    py-2\n                    text-sm\n                    font-medium\n                    text-gray-400\n                    flex-nowrap\n                    qosoor-link\n                    focus:qosoor-link-exact-active\n                    hover:qosoor-link-exact-active\n                  ",
      staticStyle: {
        "margin-left": "0",
        "margin-right": "0"
      },
      attrs: {
        "to": _vm.localePath(navLink.path),
        "exact-active-class": "qosoor-link-exact-active"
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.$t(navLink.translate)) + "\n                ")]) : _vm._e();
  }), _vm._v(" "), _vm._l(_vm.externalNavLinks, function (navLink, index) {
    return _c('a', {
      key: 'navTopLinks' + index,
      staticClass: "\n                    ml-0\n                    flex-no-wrap\n                    px-3\n                    py-2\n                    text-sm\n                    font-medium\n                    flex-nowrap\n                    qosoor-link\n                    focus:qosoor-link-exact-active\n                    hover:qosoor-link-exact-active",
      class: [_vm.isPlaceApp ? 'text-white' : 'text-gray-400'],
      staticStyle: {
        "margin-left": "0",
        "margin-right": "0"
      },
      attrs: {
        "href": _vm.localePath(navLink.path),
        "target": navLink.target
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.$t(navLink.translate)) + "\n              ")]);
  }), _vm._v(" "), _c('a', {
    key: "navContact",
    staticClass: "flex-no-wrap px-3 py-2 text-sm font-medium flex-nowrap qosoor-link focus:qosoor-link-exact-active hover:qosoor-link-exact-active",
    staticStyle: {
      "margin-left": "0",
      "margin-right": "0"
    },
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('VDropdown', {
    attrs: {
      "placement": "right"
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function () {
        return [_c('span', {
          staticClass: "\n                          dontCloseDropdown\n                          flex-no-wrap\n                          px-3\n                          py-2\n                          text-sm\n                          font-medium\n                          flex-nowrap\n                          qosoor-link\n                        ",
          class: [_vm.isPlaceApp ? 'text-white' : 'text-gray-400']
        }, [_vm._v("\n                        " + _vm._s(_vm.$t('nav.contact')) + "\n                      ")]), _vm._v(" "), _c('svg', {
          staticClass: "w-4 h-4",
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "viewBox": "0 0 20 20"
          }
        }, [_c('path', {
          attrs: {
            "d": "M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
          }
        })])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('a', {
          staticClass: "\n                          flex\n                          w-full\n                          justify-between\n                          items-center\n                          rounded\n                          px-2\n                          py-1\n                          my-1\n                          hover:bg-primary-600 hover:text-white\n                        ",
          attrs: {
            "href": "mailto:info@eventful.sa",
            "target": "_blank"
          }
        }, [_vm._v("\n                        البريد الالكتروني\n                        "), _c('i', {
          staticClass: "fas fa-envelope w-2"
        })]), _vm._v(" "), _c('a', {
          staticClass: "\n                          flex\n                          w-full\n                          justify-between\n                          items-center\n                          rounded\n                          px-2\n                          py-1\n                          my-1\n                          hover:bg-primary-600 hover:text-white\n                        ",
          attrs: {
            "href": "https://api.whatsapp.com/send/?phone=966504849043",
            "target": "_blank"
          }
        }, [_vm._v("\n                        وتساب\n                        "), _c('i', {
          staticClass: "fab fa-whatsapp w-2"
        })])];
      },
      proxy: true
    }], null, false, 2854028647)
  })], 1)], 2), _vm._v(" "), _vm._l(_vm.availableLocales, function (locale) {
    return _c('NuxtLink', {
      key: locale.code,
      staticClass: "\n                  flex-no-wrap\n                  px-3\n                  py-2\n                  text-sm\n                  font-medium\n                  flex-nowrap\n                  qosoor-link\n                  focus:qosoor-link-exact-active\n                  hover:qosoor-link-exact-active\n                ",
      class: [_vm.isPlaceApp ? 'text-white' : 'text-gray-900'],
      attrs: {
        "to": _vm.switchLocalePath(locale.code)
      }
    }, [_c('span', {
      staticClass: "flex items-center"
    }, [_vm._v("\n                  " + _vm._s(locale.name) + "\n                  "), _c('img', {
      staticClass: "ps-2 text-white",
      class: [_vm.isPlaceApp ? 'fill-white' : ''],
      attrs: {
        "id": "arrow-svg",
        "src": require('~/assets/images/languages/' + locale.icon),
        "alt": "Switch Language"
      }
    })])]);
  })], 2)])])]), _vm._v(" "), _vm.isOpenMenu ? _c('div', {
    staticClass: "lg:hidden",
    attrs: {
      "id": "mobile-menu"
    }
  }, [_c('div', {
    staticClass: "px-2 pt-2 pb-3 space-y-1 shadow-xl"
  }, [_vm._l(_vm.navLinks, function (navLink, index) {
    return _c('NuxtLink', {
      key: 'nav' + index,
      staticClass: "\n              block\n              px-3\n              py-2\n              text-sm\n              font-medium\n              text-gray-900\n              qosoor-link\n              focus:qosoor-link-exact-active:right\n              hover:qosoor-link-exact-active:right\n            ",
      attrs: {
        "to": _vm.localePath(navLink.path),
        "exact-active-class": "qosoor-link-exact-active:right "
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$t(navLink.translate)))])]);
  }), _vm._v(" "), _vm._l(_vm.externalNavLinks, function (navLink, index) {
    return _c('a', {
      key: 'nav' + index,
      staticClass: "\n              block\n              px-3\n              py-2\n              text-sm\n              font-medium\n              text-gray-900\n              qosoor-link\n              focus:qosoor-link-exact-active:right\n              hover:qosoor-link-exact-active:right\n            ",
      attrs: {
        "href": navLink.path,
        "target": "_blank"
      }
    }, [_vm._v("\n            " + _vm._s(_vm.$t(navLink.translate)) + "\n          ")]);
  }), _vm._v(" "), _vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm.isLogin ? _c('button', {
    staticClass: "\n              block\n              w-full\n              px-3\n              py-2\n              text-sm\n              font-medium\n              text-gray-900 text-start\n              qosoor-link\n              focus:qosoor-link-exact-active:right\n              hover:qosoor-link-exact-active:right\n            ",
    on: {
      "click": _vm.doLogout
    }
  }, [_c('span', {}, [_c('i', {
    staticClass: "fas fa-unlock-alt pe-2"
  }), _vm._v("\n              " + _vm._s(_vm.$t('nav.logout')) + "\n            ")])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.availableLocales, function (locale) {
    return _c('NuxtLink', {
      key: locale.code,
      staticClass: "\n              block\n              px-3\n              py-4\n              text-sm\n              font-medium\n              qosoor-link\n              focus:qosoor-link-exact-active:right\n              hover:qosoor-link-exact-active:right\n            ",
      class: [_vm.isPlaceApp ? 'text-white' : 'text-gray-900'],
      attrs: {
        "to": _vm.switchLocalePath(locale.code)
      }
    }, [_c('span', {
      staticClass: "flex flex-no-wrap items-center"
    }, [_c('img', {
      staticClass: "pe-4",
      attrs: {
        "src": require('~/assets/images/languages/' + locale.icon),
        "alt": "Switch Language"
      }
    }), _vm._v("\n              " + _vm._s(locale.name) + "\n            ")])]);
  })], 2)]) : _vm._e()]) : _vm._e(), _vm._v(" "), !_vm.isPlaceApp && !_vm.isHiddenAppHint ? _c('div', {
    staticClass: "fixed fixed-bottom md:hidden px-4 w-full",
    staticStyle: {
      "bottom": "1rem",
      "left": "0",
      "right": "0",
      "z-index": "99"
    }
  }, [_vm._m(2)]) : _vm._e(), _vm._v(" "), !_vm.isPlannerAiPage ? _c('div', [!_vm.isPlaceApp ? _c('div', {
    staticClass: "block h-13"
  }) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "\n              block\n              px-3\n              py-2\n              text-sm\n              font-medium\n              text-gray-900\n              qosoor-link\n              focus:qosoor-link-exact-active:right\n              hover:qosoor-link-exact-active:right\n            ",
    attrs: {
      "href": "mailto:info@eventful.sa",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "fas fa-envelope w-2 me-4"
  }), _vm._v("\n            البريد الالكتروني\n          ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "\n              block\n              px-3\n              py-2\n              text-sm\n              font-medium\n              text-gray-900\n              qosoor-link\n              focus:qosoor-link-exact-active:right\n              hover:qosoor-link-exact-active:right\n            ",
    attrs: {
      "href": "https://api.whatsapp.com/send/?phone=966504849043",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "fab fa-whatsapp w-2 me-4"
  }), _vm._v("\n            وتساب\n          ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white rounded-md flex justify-between px-5 py-2 items-center w-full shadow-lg"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("text")])]);
}]

export { render, staticRenderFns }