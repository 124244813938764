//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  computed: {
    place() {
      return this.$store.state.home.place
    },
  },
  methods: {
    currentYear() {
      return new Date().getFullYear() ?? '2022'
    },
  },
}
