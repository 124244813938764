var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative"
  }, [_c('button', {
    staticClass: "z-10 relative flex items-center focus:outline-none select-none",
    on: {
      "click": function ($event) {
        _vm.open = !_vm.open;
      }
    }
  }, [_vm._t("button")], 2), _vm._v(" "), _vm.open ? _c('button', {
    staticClass: "fixed inset-0 h-full w-full cursor-default focus:outline-none",
    attrs: {
      "tabindex": "-1"
    },
    on: {
      "click": function ($event) {
        _vm.open = false;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('transition', {
    attrs: {
      "enter-active-class": "transition-all duration-200 ease-out",
      "leave-active-class": "transition-all duration-750 ease-in",
      "enter-class": "opacity-0 scale-75",
      "enter-to-class": "opacity-100 scale-100",
      "leave-class": "opacity-100 scale-100",
      "leave-to-class": "opacity-0 scale-75"
    }
  }, [_vm.open ? _c('div', {
    staticClass: "absolute shadow-lg border w-48 rounded py-1 px-2 text-sm mt-4 bg-white z-10",
    class: _vm.placement === 'right' ? 'right-0' : 'left-0'
  }, [_vm._t("content")], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }